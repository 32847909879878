<template>
  <div class="contact">
    <p class="cut-off-line"></p>
    <div class="support">
      <img
        loading="lazy"
        class="img80"
        src="@/assets/img/personal/support.svg"
        alt="support"
      />
      <div>
        <p class="small-title">{{ $t("contact.support_center") }}</p>
        <p>{{ $t("contact.support_tip") }}</p>
        <a
          :href="vendorInfo.supportLink"
          title="support"
          style="text-decoration: none"
          target="_blank"
        >
          <p class="p-btn usable mt10">
            {{ $t("contact.support_center") }}
          </p>
        </a>
      </div>
    </div>
    <p class="cut-off-line"></p>
    <div class="feedback">
      <img
        loading="lazy"
        class="img80"
        src="@/assets/img/personal/feedback.svg"
        alt="feedback"
      />
      <div>
        <p class="small-title">{{ $t("contact.feedback") }}</p>
        <input type="text" v-model="email" disabled />
        <div
          class="custom-select"
          :class="isRequest ? 'readonly' : isSelectSystem ? 'active' : ''"
        >
          <p @click.stop="toSelect(1)">{{ selectedSystem }}<span></span></p>
          <div class="select-options" v-show="isSelectSystem">
            <p
              v-for="(s, i) in systems"
              :key="i"
              :class="selectedSystem == s ? 'active' : ''"
              @click="chooseSystem(s)"
            >
              {{ s }}
            </p>
          </div>
        </div>
        <div
          v-show="!isLoading"
          class="custom-select"
          :class="isRequest ? 'readonly' : isSelectSoftware ? 'active' : ''"
        >
          <p
            @click.stop="toSelect(0)"
            :class="selectedSoftware != -1 ? '' : 'f-gray'"
          >
            <span
              ><img
                v-if="selectedSoftware != -1"
                loading="lazy"
                class="img40"
                :src="allSoftware[selectedSoftware]['icon']"
                :alt="allSoftware[selectedSoftware]['name']"
              />
              <span>
                {{
                  allSoftware[selectedSoftware]
                    ? allSoftware[selectedSoftware]["name"]
                    : $tc("contact.select")
                }}
              </span>
            </span>
            <span></span>
          </p>
          <div class="select-options" v-show="isSelectSoftware">
            <p
              v-for="(s, i) in allSoftware"
              :key="i"
              :class="selectedSoftware == i ? 'active' : ''"
              @click="chooseSoftware(i)"
              :style="{
                display: hiddenItems.includes(i) ? 'none' : 'flex',
              }"
            >
              <img
                loading="lazy"
                class="img40"
                :src="s.icon"
                alt="product logo"
                @error="handleImageError(i)"
              />
              <span>{{ s.name }}</span>
            </p>
          </div>
        </div>
        <textarea
          v-model="feedbackContent"
          :placeholder="$t('contact.content_placeholder')"
        ></textarea>
        <p
          class="p-btn usable submit-btn"
          :class="isRequest ? 'isLoading' : ''"
          @click="submitFeedback"
        >
          {{ isRequest ? "" : $t("contact.submit") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAllSoftware } from "@/api/dmapi/user.js";
import { feedback } from "@/api/otherSources.js";
import { fbTracking } from "@/utils/parse.js";

export default {
  name: "contactSupport",
  props: {
    userInfo: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      email: "",
      selectedSoftware: -1,
      selectedSystem: "",
      feedbackContent: "",
      isSelectSoftware: false,
      isSelectSystem: false,
      isRequest: false,
      systems: ["Win", "Mac", "Android", "Online"],
      allSoftware: [],
      isLoading: true,
      hiddenItems: [],
    };
  },
  computed: {
    ...mapGetters(["vendorInfo"]),
  },
  created() {
    const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    isMac ? this.chooseSystem("Mac") : this.chooseSystem("Win");
  },
  mounted() {
    this.email = this.userInfo.email;
  },
  methods: {
    submitFeedback() {
      if (!this.feedbackContent) {
        this.$parent.isError = true;
        this.$parent.popupText = this.$t("contact.enter_content");
        return;
      }
      if (this.selectedSoftware == -1) {
        this.$parent.isError = true;
        this.$parent.popupText = this.$t("contact.select_software");
        return;
      }
      this.isRequest = true;
      const pro =
        this.selectedSoftware == 0
          ? `${this.vendorInfo.name}会员系统`
          : this.allSoftware[this.selectedSoftware].name;

      // 获取软件对应的参数，有版本号的话
      const formData = new FormData();
      formData.append("product", pro);
      formData.append("userID", this.userInfo.id);
      formData.append("event", "feedback");
      formData.append("email", this.userInfo.email);
      formData.append("content", this.feedbackContent);
      formData.append("os", this.selectedSystem);
      // version: "1.0.0",
      // os_version: "10.0.22621",

      feedback(formData)
        .then(() => {
          this.isRequest = false;
          this.$parent.isSuccess = true;
          this.$parent.popupText = this.$t("contact.feedback_success");
          this.feedbackContent = "";
        })
        .catch(() => {
          this.isRequest = false;
          this.$parent.isError = true;
          this.$parent.popupText = this.$t("contact.feedback_fail");
        });
      const parseInfo = {
        location: "support",
        name: pro,
        language: `Browser: ${navigator.language},website:${this.$i18n.locale}`,
      };
      fbTracking(parseInfo, [this.selectedSystem, this.feedbackContent]);
    },
    chooseSoftware(i) {
      this.isSelectSoftware = false;
      this.selectedSoftware = i;
    },
    chooseSystem(s) {
      this.hiddenItems = [];
      this.isSelectSystem = false;
      this.selectedSystem = s;
      this.selectedSoftware = -1;
      this.isLoading = true;
      getAllSoftware(s.slice().toLocaleLowerCase())
        .then(res => {
          this.allSoftware = res.data.list;
          this.allSoftware.unshift({
            name: this.$t("common.vip_system"),
            icon: this.vendorInfo.logo,
          });
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    toSelect(n) {
      if (this.isRequest) return;
      this.isSelectSoftware = n == 0 ? !this.isSelectSoftware : false;
      this.isSelectSystem = n == 0 ? false : !this.isSelectSystem;
      document.addEventListener("click", this.closeSelect);
    },
    closeSelect() {
      this.isSelectSoftware = false;
      this.isSelectSystem = false;
      document.removeEventListener("click", this.closeSelect);
    },
    handleImageError(i) {
      this.hiddenItems.push(i);
    },
  },
};
</script>
