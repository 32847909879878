import axios from "axios";

const isPro = process.env.NODE_ENV == "production";

export function feedback(formData) {
  const url = isPro
    ? "https://log.anywork.site/feedback/software/"
    : "/contact/feedback/software/";
  return axios.post(url, formData);
}
