<template>
  <div id="buttonDiv" ref="googleLogin"></div>
</template>

<script>
import { googleLogin, jwtLogin } from "@/api/login.js";
import { mapGetters } from "vuex";
import jwtDecode from "jwt-decode";

export default {
  name: "googleLogin",
  data() {
    return {
      btnText: {
        login: "signin_with",
        register: "signup_with",
      },
    };
  },
  props: {
    isNewCustomerActive: {
      type: Boolean,
      default: false,
    },
    other: {
      type: Object,
    },
    page: {
      type: String,
    },
    acceptNewsletterValue: {
      type: Number,
    },
    fp: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["vendorInfo"]),
  },
  methods: {
    loadGoogleLogin() {
      const id =
        JSON.parse(process.env.VUE_APP_PRE_PUBLISH || false) &&
        this.vendorInfo.preGoogleClientId
          ? this.vendorInfo.preGoogleClientId
          : this.vendorInfo.googleClientId;
      window.google.accounts.id.initialize({
        client_id: id,
        callback: this.handleCredentialResponse,
        use_fedcm_for_prompt: true,
      });
      window.google.accounts.id.renderButton(this.$refs.googleLogin, {
        theme: "outline",
        size: "large",
        text: this.btnText[this.page],
        shape: "circle",
        width: this.$parent.$el.clientWidth,
        locale: this.$i18n.locale,
      });
    },
    handleCredentialResponse(response) {
      let other =
        this.page == "register"
          ? this.isNewCustomerActive
            ? {
                ...this.other,
                accept_newsletter: this.acceptNewsletterValue,
                finger_print: this.fp,
                fp_version: window.fpVersion,
              }
            : {
                accept_newsletter: this.acceptNewsletterValue,
                finger_print: this.fp,
                fp_version: window.fpVersion,
              }
          : {};
      const pageName = this.page == "login" ? "Login" : "Register";
      this.$eventTracking({
        event: `ClickGoogle${pageName}`,
        location: `${pageName}Window`,
      });
      googleLogin({
        ...jwtDecode(response.credential),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        id_token: response.credential,
        ...other,
      })
        .then(res => {
          if (res.status == 200) {
            try {
              this.$eventTracking({
                event: `Google${pageName}Result`,
                location: `${pageName}Window`,
                result: "Success",
              });
              if (
                this.vendorInfo.name === "TuneFab" &&
                process.env.NODE_ENV === "production"
              ) {
                window.dataLayer.push({
                  event: "google_signin",
                  method: "Google",
                });
              }
            } catch (e) {
              console.error(e);
            }

            if (res.data.create_user == 1 && this.isNewCustomerActive) {
              this.googleRegisterSuccess(res.data.token);
              return;
            }
            this.$navigateTo({ text: "谷歌登录" }, res.data.url);
          } else {
            this.$eventTracking({
              event: `Google${pageName}Result`,
              location: `${pageName}Window`,
              result: "Failed",
              reason: res.data?.message || res.message || res,
            });
          }
        })
        .catch(err => {
          this.$eventTracking({
            event: `Google${pageName}Result`,
            location: `${pageName}Window`,
            result: "Failed",
            reason: err.data?.message || err.message || err,
          });
        });
    },
    googleRegisterSuccess(token) {
      jwtLogin(token).then(res => {
        this.$cookieManager.setCookie("_token", res.data, 1);
        this.$store.commit("dmCommon/changeLoginStatus", true);
        window.location.href = this.$route.query.redirect;
      });
    },
  },
  mounted() {
    if (window.google) {
      this.loadGoogleLogin();
    } else {
      // 所有脚本加载完
      window.addEventListener("load", () => {
        if (!window.google) return;
        this.loadGoogleLogin();
      });
    }

    window.onresize = () => {
      if (!window.google) return;
      window.google.accounts.id.renderButton(this.$refs.googleLogin, {
        theme: "outline",
        size: "large",
        text: this.btnText[this.page],
        shape: "circle",
        width: this.$parent.$el.clientWidth,
        locale: this.$i18n.locale,
      });
    };
  },
};
</script>
