<template>
  <div class="personal-info">
    <div class="profile">
      <img loading="lazy" :src="profileUrl" alt="profile" />
      <p class="p-a" @click="chooseFile">
        {{ $t("userInfoModule.edit") }}
      </p>
      <input
        type="file"
        ref="fileInput"
        style="display: none"
        accept="image/*"
        @change="changeProfile"
      />
    </div>
    <div class="main-content">
      <div>
        <p>{{ $t("common.first_name") }}</p>
        <input
          type="text"
          v-model="name.first_name"
          :placeholder="$t('common.first_name')"
          @input="validateName('first_name')"
        />
      </div>
      <div>
        <p>{{ $t("common.last_name") }}</p>
        <input
          type="text"
          v-model="name.last_name"
          :placeholder="$t('common.last_name')"
          @input="validateName('last_name')"
        />
      </div>
      <p class="error-tip">{{ nameError }}</p>
      <p
        class="p-btn update-btn"
        :class="isLoading ? 'isLoading' : canUpdateName ? 'usable' : 'disabled'"
        @click="updateUsername()"
      >
        {{ $t("userInfoModule.update") }}
      </p>
      <div>
        <p>{{ $t("common.email") }}</p>
        <input type="text" v-model="email" disabled />
      </div>
      <div>
        <p>{{ $t("userInfoModule.reg_time") }}</p>
        <input type="text" v-model="regTime" disabled />
      </div>
      <p class="destroy-btn" @click="destroyAccount">
        {{ $t("userInfoModule.destroy") }}
      </p>
    </div>
  </div>
</template>

<script>
import { updateUserInfo, avatarEdit } from "@/api/user.js";
import { destroy } from "@/api/login.js";
import { mapGetters } from "vuex";

export default {
  name: "userInfo",
  props: {
    userInfo: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      name: {
        first_name: "",
        init_first_name: "",
        first_name_result: true,
        last_name: "",
        init_last_name: "",
        last_name_result: true,
      },
      nameError: "",
      isLoading: false,
      email: "",
      regTime: "",
      profileUrl: this.userInfo.avatar
        ? this.userInfo.avatar
        : require("@/assets/img/personal/init-profile.svg"),
    };
  },
  computed: {
    ...mapGetters(["isPro"]),
    canUpdateName() {
      return (
        (this.name.first_name !== this.name.init_first_name ||
          this.name.last_name != this.name.init_last_name) &&
        this.name.first_name_result &&
        this.name.last_name_result
      );
    },
  },
  methods: {
    updateUsername() {
      if (!this.canUpdateName) return;
      const { first_name, last_name } = this.name;
      this.isLoading = true;
      updateUserInfo({ first_name, last_name })
        .then(() => {
          this.isLoading = false;
          this.name.init_first_name = first_name;
          this.name.init_last_name = last_name;
          this.$parent.isSuccess = true;
          this.$parent.popupText = this.$t("userInfoModule.update_success");
          this.$parent.getUserInfomartion();
        })
        .catch(err => {
          this.isLoading = false;
          this.$parent.isError = true;
          this.$parent.popupText = this.isPro
            ? this.$t("common.error")
            : err.response
            ? err.response.data.message
            : err.message;
        });
    },
    validateName(filed) {
      const value = this.name[filed];
      const max = filed == "first_name" ? 30 : 20;
      if (value === "") {
        this.name[`${filed}_result`] = false;
        this.nameError = this.$t("login.enter_tip", {
          fieldName: this.$t(`common.tip_${filed}`),
        });
      } else if (value.trim().length > max) {
        this.name[`${filed}_result`] = false;
        this.nameError = this.$t("register.name_limit", {
          fieldName:
            this.$i18n.locale == "pl_PL"
              ? this.$t(`common.${filed}`)
              : this.$t(`common.tip_${filed}`),
          max: max,
        });
      } else {
        this.name[`${filed}_result`] = true;
        this.nameError = "";
      }
    },
    changeProfile(e) {
      const file = e.target.files[0];
      if (file && file.type.startsWith("image/")) {
        avatarEdit(file)
          .then(res => {
            this.profileUrl = res.data.head_img_url;
            this.$parent.getUserInfomartion();
            this.$parent.isSuccess = true;
            this.$parent.popupText = this.$t(
              "userInfoModule.profile_update_success"
            );
            e.target.value = "";
          })
          .catch(err => {
            this.$parent.isError = true;
            this.$parent.popupText = this.isPro
              ? this.$t("common.error")
              : err.response
              ? err.response.data.message
              : err.message;
            e.target.value = "";
          });
      }
    },
    chooseFile() {
      this.$refs.fileInput.click();
    },
    destroyAccount() {
      this.$parent.isWarning = true;
      this.$parent.popupText = this.$t("userInfoModule.destroy_tip");
      this.$parent.customConfirm = {
        text: this.$t("manageSubscription.confirm"),
        type: "red",
      };
    },
    handleConfirm() {
      if (this.$parent.popupText == this.$t("userInfoModule.destroy_tip")) {
        this.$parent.popupText = this.$t("userInfoModule.destroy_tip2");
        return;
      }
      this.$parent.isWaiting = true;
      destroy()
        .then(() => {
          this.$parent.isWarning = false;
          this.$parent.isWaiting = false;
          this.$parent.isSuccess = true;
          this.$parent.popupText = this.$t("userInfoModule.destroy_success");
          document.addEventListener("click", this.destorySuccess);
        })
        .catch(err => {
          this.$parent.isWarning = false;
          this.$parent.isWaiting = false;
          const msg = err.response ? err.response.data.message : err.message;
          this.$parent.isError = true;
          this.$parent.popupText =
            msg ==
            "Your subscription with normal renewal status cannot be canceled, please pause the subscription first!"
              ? this.$t("userInfoModule.have_sub")
              : this.isPro
              ? this.$t("common.error")
              : msg;
        });
    },
    destorySuccess() {
      this.$navigateTo({ text: "注销账号成功" }, "/login");
      localStorage.clear();
      sessionStorage.clear();
      document.removeEventListener("click", this.destorySuccess);
      this.$store.commit("dmCommon/changeLoginStatus", false);
      this.$store.commit("user/setUserInfo", null);
      this.$cookieManager.removeCookie("_token");
      // removeCookie("_token");
    },
  },
  mounted() {
    this.name.first_name = this.name.init_first_name = this.userInfo.first_name;
    this.name.last_name = this.name.init_last_name = this.userInfo.last_name;
    this.email = this.userInfo.email;
    this.regTime = this.userInfo.created_at;
  },
};
</script>
