import request from "@/utils/request";

export function getDevices(ss_id, sub_id) {
  return request({
    url: `/api/user/license/get_list`,
    method: "get",
    params: {
      page: 1,
      page_size: -1,
      ss_id: ss_id,
      sub_id: sub_id,
    },
  });
}
export function resetAllLicense(licenseId, noteId, type) {
  return request({
    url: `/api/user/license/reset_all`,
    method: "post",
    data: {
      license_id: licenseId,
      note_id: noteId,
      device_type: type,
    },
  });
}
export function getPurchasedProduct() {
  return request({
    url: `/api/user/purchased_software/get_lists`,
    method: "post",
    data: {
      page: 1,
      page_size: -1,
    },
  });
}
export function getPurchasedPlan() {
  return request({
    url: `/api/user/purchased_plan/get_lists`,
    method: "get",
    params: {
      page: 1,
      page_size: -1,
    },
  });
}
export function getAllSoftware(platform) {
  return request({
    url: `/api/user/software/get_lists`,
    method: "get",
    params: {
      page: 1,
      page_size: -1,
      platform: platform,
    },
  });
}
