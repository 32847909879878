const state = {
  vendorInfo: {},
  screenWidth: window.innerWidth,
  isPro: process.env.NODE_ENV == "production",
  deviceName: {
    win: "Windows",
    mac: "Mac",
    android: "Android",
    iPad: "iPad",
    iphone: "iphone",
  },
};

const mutations = {
  updateWidth(state, value) {
    state.screenWidth = value;
  },
  saveVendorInfo(state, value) {
    state.vendorInfo = value;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
