import request from "@/utils/request";

export function getPrice(data) {
  return request({
    url: "/api/user/subscribe_change/get_price",
    method: "post",
    data,
  });
}
export function getSubscription(n) {
  return request({
    url: `/api/user/subscribe/get_lists`,
    method: "post",
    data: {
      page: n,
      page_size: 10,
    },
  });
}
export function operatSub(id, type, other = {}) {
  return request({
    url: `api/user/op_subscribe`,
    method: "post",
    data: {
      order_products_id: id,
      op_type: type,
      payment_method: "airwallex",
      ...other,
    },
  });
}
export function changeSubscribe(data) {
  return request({
    url: "/api/user/subscribe_change/payment/create",
    method: "post",
    data,
  });
}
export function getRecommendation() {
  return request({
    url: `/api/recommend/software/get_lists`,
    method: "get",
    params: { page: 1, page_size: -1 },
  });
}
export function changePayment(id, currency, card_id) {
  const obj = card_id == "" ? {} : { card_id };
  return request({
    url: `/api/user/subscribe/change_payment_method`,
    method: "post",
    data: {
      payment_method: "airwallex",
      id,
      currency,
      ...obj,
    },
  });
}
export function querySubscription(email) {
  return request({
    url: `/api/interior/subscribe/get_lists`,
    method: "post",
    data: {
      email,
    },
  });
}
