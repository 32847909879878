<template>
  <div class="mask">
    <div class="custom-popup cardsComponent">
      <div class="pay-way">
        <p
          :class="payMethod == 0 ? 'pay-way-active' : ''"
          @click="payMethod = 0"
        >
          <img src="@/assets/img/shopcart/credit-card.svg" alt="credit card" />
        </p>
        <p
          :class="payMethod == 1 ? 'pay-way-active' : ''"
          @click="payMethod = 1"
        >
          <img src="@/assets/img/shopcart/google-pay.png" alt="Google Pay" />
        </p>
        <p
          :class="payMethod == 2 ? 'pay-way-active' : ''"
          @click="payMethod = 2"
        >
          <img src="@/assets/img/shopcart/payssion.png" alt="Payssion" />
        </p>
      </div>
      <div v-show="payMethod == 0" style="width: 100%">
        <div v-if="source == 'cart'">
          <payment ref="payment"></payment>
        </div>
        <div v-else class="card-list">
          <p class="loading" v-if="isLoading"></p>
          <div v-else>
            <p class="tip">
              {{ $t("manageSubscription.choose_card_tip") }}
            </p>
            <ul>
              <li
                class="pay-card"
                v-for="(c, index) in cardList"
                :key="index"
                @click="chooseCardNum(c.id)"
              >
                {{ formatCardNum(c.card) }}
              </li>
            </ul>
            <p class="p-btn usable pay-card" @click="toAddNewCard($event)">
              {{ $t("manageSubscription.add_card") }}
            </p>
          </div>
        </div>
      </div>
      <div v-show="payMethod == 2" style="width: 100%" class="card-list">
        <ul v-if="!beingProcessed">
          <li
            class="pay-card"
            v-for="(item, index) in payssionList"
            :key="index"
            @click="chooseCardNum(item.id)"
          >
            <img :src="`@/assets/img/shopcart/${item.id}.png`" :alt="item.id" />
            {{ item.name }}
          </li>
        </ul>
        <p class="loading" v-else></p>
      </div>
      <div class="btn-group">
        <p class="res-tip">{{ resText }}</p>
        <div id="googlePayButton" ref="googlebtn" v-show="payMethod == 1"></div>
        <p
          v-if="payMethod == 1 && !googlePayReady"
          class="p-btn usable"
          :class="beingProcessed ? 'isLoading' : ''"
          @click="handleGooglePay"
        >
          {{ $t("popup.confirm") }}
        </p>
        <p
          class="p-btn usable"
          v-if="source == 'cart' && payMethod == 0"
          :class="cartBeingProcessed ? 'isLoading' : ''"
          @click="cartConfirmCreate"
        >
          {{ $t("popup.confirm") }}
        </p>
        <p class="p-btn white" @click="closePopup">
          {{ $t("popup.cancel") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getCardList } from "@/api/user.js";
import payssionAPI from "@/api/payssion.js";
import { loadAirwallex } from "airwallex-payment-elements";
import payment from "@/components/paymentControl.vue";

export default {
  components: { payment },
  name: "chooseCardComponent",
  data() {
    return {
      isLoading: true,
      cardList: [],
      currentCard: null,
      payMethod: 0, //0：credit card，1：google pay,
      beingProcessed: false,
      resText: "",
      Airwallex: null,
      googlePayReady: false,
      cartBeingProcessed: false,
      payssionList: [],
    };
  },
  props: {
    source: {
      type: String,
    },
    showValue: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(["isPro"]),
  },
  methods: {
    chooseCardNum(id = null) {
      if (this.payMethod == 0) {
        this.isLoading = true;
      }
      if (this.payMethod == 2) {
        this.beingProcessed = true;
        this.$parent.payssionId = id;
      } else {
        this.$parent.currentCard = id;
      }
      this.$parent.confirmPayWay();
    },
    toAddNewCard(e) {
      if (this.source == "manage") {
        delete this.$parent.parameter.card_id;
        sessionStorage.setItem(
          "parameter",
          JSON.stringify(this.$parent.parameter)
        );
      } else if (this.source == "resume" || this.source == "change-payment") {
        sessionStorage.setItem("requsetId", this.$parent.requestId);
        sessionStorage.setItem("requestCurrency", this.$parent.requestCurrency);
      }
      this.$navigateTo(e, `/add-new-card/${this.source}`);
    },
    formatCardNum(num) {
      return num.replace(/(.{4})/g, "$1 ");
    },
    closePopup() {
      this.isLoading = false;
      this.$parent.isChoosePayment = false;
    },
    handleGooglePay() {
      this.$parent.confirmPayWay();
      this.beingProcessed = true;
    },
    googlePay(data, isLlifelong) {
      let other = isLlifelong
        ? {
            buttonType: "buy",
          }
        : {
            mode: "recurring",
            buttonType: "subscribe",
          };
      const googlePayElement = this.Airwallex.createElement("googlePayButton", {
        ...other,
        intent_id: data.id,
        origin: window.location.origin,
        client_secret: data.client_secret,
        countryCode: "US", // The merchant's two-letter ISO 3166       totalPriceLabel: 'COMPANY, INC.', // Provide a business name for the label field.
        customer_id: data.customer_id,
        merchantInfo: {
          merchantName: "Example Merchant",
        },
        amount: {
          //   value: intent.amount,
          currency: data.currencyType || data.currency,
        },
      });
      const googlePay = googlePayElement.mount("googlePayButton");
      googlePay.addEventListener("onReady", event => {
        console.log("googlePay onReady:", event);
        this.beingProcessed = false;
        this.googlePayReady = true;
      });
      googlePay.addEventListener("onSuccess", event => {
        console.log("success", event);
        this.$parent.isChoosePayment = false;
        this.source == "cart"
          ? this.$parent.buySuccess()
          : this.$parent.successPayment();
      });
      googlePay.addEventListener("onError", err => {
        console.log("error", err, err.message);
        this.resText = err.message;
        this.beingProcessed = false;
      });
    },
    cartConfirmCreate() {
      this.cartBeingProcessed = true;
      this.$parent.confirmPayWay();
    },
    cartCreateOrder(n, res) {
      if (n == 0) {
        this.$refs.payment.confirmPayment(res);
      } else {
        this.$refs.payment.createConsent(res);
      }
    },
    // 购物车支付成功
    successPayment() {
      this.isLoading = false;
      this.$parent.isChoosePayment = false;
      this.$parent.buySuccess();
    },
  },
  mounted() {
    getCardList(1, 10)
      .then(res => {
        this.cardList = res.data.list;
      })
      .catch(() => {})
      .finally(() => {
        this.isLoading = false;
      });

    loadAirwallex({
      env: this.isPro ? "prod" : "demo",
      origin: window.location.origin,
    }).then(res => {
      this.Airwallex = res;
    });

    payssionAPI.getPaymentList().then(res => {
      this.payssionList = res.data.list;
    });
  },
  watch: {
    showValue(newValue) {
      if (newValue) {
        this.$refs.googlebtn.innerHTML = "";
        this.googlePayReady = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/manageSubscription";

.pay-card {
  list-style: none;
  width: 100%;
  display: flex;
  border-radius: 5px;
  padding: 10px 15px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  background: transparent !important;
  color: #000 !important;
  justify-content: flex-start;
  cursor: pointer;
}

.loading {
  width: 40px;
  height: 40px;
  border: solid #6086e8;
  border-width: 0 0 2px 2px;
  border-radius: 50%;
  animation: circle infinite 1s linear;
  margin: 0 auto;
}

.cardsComponent.custom-popup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #f2f2f2;
  width: fit-content;

  @include columnGap(20px);

  transition: all 0.2s;
}

.pay-way {
  display: flex;

  @include rowGap(10px);

  > p {
    padding: 10px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #fff;

    &.pay-way-active {
      border: 1px solid #6086e8;
      position: relative;

      &::after {
        content: "√";
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        color: #fff;
        background: #6086e8;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        line-height: 25px;
        position: absolute;
        right: -12px;
        top: -12px;
      }
    }
  }
}

.card-list {
  width: 100%;

  .tip {
    color: #686868;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .pay-card {
    align-items: center;
  }

  img {
    width: 80px;
    height: 40px;
    margin-right: 10px;
  }
}

.btn-group {
  width: 100%;

  @include columnGap(10px);
}

.res-tip {
  color: red;
}

#googlePayButton {
  margin: 20px 0;
  width: 100%;

  &:empty {
    margin: 0;
  }
}
</style>
