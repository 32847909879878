import { getCookie } from "@/utils/operateCookie.js";
const state = {
  systemHasLogin: !!getCookie("_token"),
  showMenu: false,
};

const mutations = {
  changeLoginStatus(state, bool) {
    state.systemHasLogin = bool;
  },
  changeMenuStatus(state, bool) {
    state.showMenu = bool;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
