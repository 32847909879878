<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="scss">
#app,
input {
  font-family: MiSans, sans-serif;
}

body {
  width: 100%;
  padding: 0 !important;
  overflow-y: scroll !important;

  .el-input__inner,
  input,
  p,
  .el-input-group__append {
    color: #000;

    @include font("sm", 16px, 20px, 400);
    @include font("base", 12px, 14px, 400);
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

// 按钮加载中
.el-loading-mask {
  background-color: transparent !important;

  .el-loading-spinner {
    .circular {
      height: 30px;
      width: 30px;
      margin: 6px;
    }

    .path {
      stroke: #fff;
    }
  }
}
</style>
