import store from "../store";

export default class cookieManager {
  domains = new Set();
  local = this.getUrlSuffix(location);
  constructor(obj) {
    const main = new URL(obj["en_US"]);
    const mainDomain = this.getUrlSuffix(main);
    Object.values(obj).forEach(url => {
      const suffix = this.getUrlSuffix(new URL(url));
      if (suffix !== mainDomain && suffix !== this.local) {
        this.domains.add(url);
      }
    });
    if (this.local != mainDomain) {
      this.domains.add(obj["en_US"]);
    }
  }

  getUrlSuffix(url) {
    const param = url?.hostname || url;
    const match = param.match(/\.[a-z]+\.[a-z]+$/);
    return match ? match[0] : param;
  }

  /**
   * Set a cookie.
   * The cookie will be set for all domains in the domains set.
   *
   * @param {string} c_name - the name of the cookie
   * @param {string} value - the value of the cookie
   * @param {number} time - the expiration time of the cookie
   * @param {number} expirationType - the type of expiration time of the cookie (0: days, 1: timestamp, default: 0)
   */
  setCookie(c_name, value, time, expirationType = 0) {
    let exdate = new Date();
    switch (expirationType) {
      case 0:
        exdate.setDate(exdate.getDate() + time);
        break;
      case 1:
        exdate = new Date(time * 1000);
        exdate.setDate(exdate.getDate());
        break;
      default:
        break;
    }
    const param =
      c_name +
      "=" +
      escape(value) +
      (exdate instanceof Date && !isNaN(exdate)
        ? `; expires=${exdate.toUTCString()}`
        : "") +
      ";path=/;domain=";
    document.cookie = param + this.local;
    tfSetCookie(value);
    // this.syncStatus(param);
  }

  removeCookie(c_name) {
    const param =
      c_name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=";
    document.cookie = param + this.local;
    // this.syncStatus(param);
  }

  syncStatus(param) {
    // 同步登录状态给其他域名
    // this.domains
    console.log(param);
  }
}
export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    var c_start = document.cookie.indexOf(c_name + "=");
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      var c_end = document.cookie.indexOf(";", c_start);
      if (c_end == -1) c_end = document.cookie.length;
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
}

export const tfSetCookie = token => {
  if (store.getters.vendorInfo.name != "TuneFab") return;
  window.handleResquest(token);
};
