import request from "@/utils/request";

const payssionAPI = {
  getPaymentList() {
    return request({
      url: `/api/payment/get_payment_list`,
      method: "post",
      data: { country: "RU" },
    });
  },
  createPayment(data) {
    return request({
      url: `/api/payment/create`,
      method: "post",
      data,
    });
  },
};
export default payssionAPI;
