const state = {
  currentManageSub: JSON.parse(sessionStorage.getItem("currentManageSub")),
};

const mutations = {
  toManage(state, sub) {
    state.currentManageSub = sub;
    sessionStorage.setItem("currentManageSub", JSON.stringify(sub));
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
