import request from "@/utils/request";

export function getBillingList(n, m, y) {
  return request({
    url: `/api/user/order/get_lists`,
    method: "post",
    data: {
      page: n,
      page_size: m,
      year: y,
    },
  });
}
