import operateCookie from "@/utils/operateCookie.js";
import axios from "axios";

async function initVendor() {
  window.reqUrl =
    process.env.NODE_ENV == "development" ? "/local" : location.origin;
  const response = await axios.get(window.reqUrl);
  const h = response.headers["cli-h"];
  const config = (await import(`@/config/vendor/${h}.js`)).default;

  window.analyticInterface = config.analyticInterface;
  window.vendorName = config.name;
  window.cookieManager = new operateCookie(config.officialLink);
  sessionStorage.setItem("vendor", config.vendor);
  document.title = `${config.name} Dashboard`;
  let $favicon = document.querySelector('link[rel="icon"]');
  if ($favicon !== null) {
    $favicon.href = config.logo;
  } else {
    $favicon = document.createElement("link");
    $favicon.rel = "icon";
    $favicon.href = config.logo;
    document.head.appendChild($favicon);
  }
  return config;
}

export { initVendor };
