<template>
  <div class="update-pwd">
    <el-form
      ref="updatePwdForm"
      :rules="updateRules"
      :model="updateInfo"
      @validate="handleValidation"
    >
      <el-form-item prop="currentPassword">
        <p>
          {{ $t("userInfoModule.updatePwd.current_pwd") }}
        </p>
        <div class="forget-container">
          <el-input
            class="el-p-input current-pwd"
            v-model="updateInfo.currentPassword"
            :type="showPwd0 ? 'text' : 'password'"
            autocomplete="off"
            :placeholder="$t('userInfoModule.updatePwd.current_pwd_p')"
          >
            <eye
              slot="suffix"
              :isText="showPwd0"
              @changeStatus="showPwd0 = !showPwd0"
            ></eye>
          </el-input>
          <span
            class="p-a forgot-btn"
            @click="$navigateTo($event, '/reset-password')"
          >
            {{ $t("userInfoModule.updatePwd.forgot") }}
          </span>
        </div>
      </el-form-item>
      <el-form-item prop="newPassword">
        <p>{{ $t("update.new_pwd") }}</p>
        <el-input
          class="el-p-input"
          v-model="updateInfo.newPassword"
          :type="showPwd1 ? 'text' : 'password'"
          :placeholder="$t('userInfoModule.updatePwd.new_pwd_p')"
          autocomplete="off"
        >
          <eye
            slot="suffix"
            :isText="showPwd1"
            @changeStatus="showPwd1 = !showPwd1"
          ></eye>
        </el-input>
      </el-form-item>
      <el-form-item prop="rePassword">
        <p>{{ $t("register.confirm_pwd") }}</p>
        <el-input
          class="el-p-input"
          v-model="updateInfo.rePassword"
          :type="showPwd2 ? 'text' : 'password'"
          :placeholder="$t('userInfoModule.updatePwd.confirm_pwd_p')"
          autocomplete="off"
        >
          <eye
            slot="suffix"
            :isText="showPwd2"
            @changeStatus="showPwd2 = !showPwd2"
          ></eye>
        </el-input>
      </el-form-item>
      <p
        class="p-btn update-pwd-btn"
        :class="
          updateInfo.currentPassword == updateInfo.newPassword
            ? 'disabled'
            : 'usable'
        "
        @click.prevent="
          handleChangePwd(updateInfo.currentPassword == updateInfo.newPassword)
        "
      >
        {{ $t("userInfoModule.updatePwd.update_btn") }}
      </p>
    </el-form>
  </div>
</template>

<script>
import eye from "@/components/eye.vue";
import { isJSONString, validatePassword2 } from "@/utils/formValidation.js";
import { changePwd } from "@/api/login.js";
import { mapGetters } from "vuex";

export default {
  components: { eye },
  name: "userInfo",
  props: {
    userInfo: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      // 修改密码
      updateInfo: {
        currentPassword: "",
        newPassword: "",
        rePassword: "",
      },
      updateRules: {
        currentPassword: [
          {
            // validator: validateLoginPwd, 不限制特殊字符，与提示不符合
            validator: validatePassword2,
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            validator: validatePassword2,
            trigger: "blur",
          },
        ],
        rePassword: [{ validator: this.contrastPwd, trigger: "blur" }],
      },
      showPwd0: false,
      showPwd1: false,
      showPwd2: false,
      timer: null,
    };
  },
  computed: { ...mapGetters(["isPro"]) },
  methods: {
    contrastPwd(rule, value, callback) {
      if (this.updateInfo.newPassword === value) {
        callback();
      } else {
        callback(new Error(this.$t("register.pwd_different")));
      }
    },
    handleValidation(prop, isValid, errorMessage) {
      if (!isValid) {
        const filteredChildren = this.$refs.updatePwdForm.$children.filter(
          item => item.prop === prop
        );
        filteredChildren[0].validateMessage = isJSONString(errorMessage)
          ? this.$t(JSON.parse(errorMessage).t)
          : errorMessage;
      }
    },
    handleChangePwd(bool) {
      if (bool) return;
      this.$refs.updatePwdForm.validate(res => {
        if (res) {
          const info = this.updateInfo;
          const data = {
            current_password: info.currentPassword,
            password: info.newPassword,
            password_confirmation: info.rePassword,
          };
          changePwd(data)
            .then(() => {
              this.updateInfo = {
                currentPassword: "",
                newPassword: "",
                rePassword: "",
              };
              this.$parent.popupText = this.$t(
                "userInfoModule.pwd_update_success"
              );
              this.$parent.isSuccess = true;
              document.addEventListener("click", this.updateSuccess);
              this.timer = setTimeout(() => {
                this.updateSuccess();
                clearTimeout(this.timer);
              }, 3000);
            })
            .catch(err => {
              this.$parent.isError = true;
              const msg = err.response
                ? err.response.data.message
                : err.message;
              this.$parent.popupText =
                msg == "Wrong user password!"
                  ? this.$t("userInfoModule.pwd_wrong")
                  : this.isPro
                  ? this.$t("common.error")
                  : msg;
            });
        }
      });
    },
    updateSuccess() {
      this.$store.commit("dmCommon/changeLoginStatus", false);
      this.$navigateTo({ text: "更改密码成功" }, "/login");
      this.$store.commit("user/setUserInfo", null);
      this.$cookieManager.removeCookie("_token");
      // removeCookie("_token");
      clearTimeout(this.timer);
      document.removeEventListener("click", this.updateSuccess);
    },
  },
  mounted() {},
};
</script>
