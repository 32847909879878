<template>
  <div class="billing">
    <div class="custom-select" :class="isSelect ? 'active' : ''">
      <p @click="isSelect = !isSelect">{{ currentYear }}<span></span></p>
      <div class="select-options" v-show="isSelect">
        <p
          v-for="(y, i) in allYears"
          :key="i"
          :class="currentYear == y ? 'active' : ''"
          @click="chooseYear(y)"
        >
          {{ y }}
        </p>
      </div>
    </div>
    <div class="billing-container" v-if="displayedData.length != 0">
      <div class="table-head">
        <p class="text-center">{{ $t("order_complete.name") }}</p>
        <p>{{ $t("receipt.date") }}</p>
        <p>{{ $t("receipt.pay_method") }}</p>
        <p>{{ $t("receipt.price") }}</p>
        <p>{{ $t("receipt.receipt_text") }}</p>
      </div>
      <div class="table-row a-billing" v-for="(b, i) in displayedData" :key="i">
        <p>
          <span style="display: flex; align-items: center">
            <img
              loading="lazy"
              :src="b.buy_type == 4 ? b.logo : b.icon"
              alt="product logo"
            />{{ b.products[0].plan_name || b.products[0].name }}
          </span>
        </p>
        <p class="f-14 mt10">
          <span class="attribute-name-bottom">{{ $t("receipt.date") }}:</span
          >{{ b.created_at }}
        </p>
        <p>
          <span class="attribute-name-bottom"
            >{{ $t("receipt.pay_method") }}:</span
          >
          <span style="display: flex; align-items: center">
            <img
              v-if="b.bank_icon"
              loading="lazy"
              :src="b.bank_icon"
              alt="card icon"
            />
            <span> {{ b.card_num }}</span>
          </span>
        </p>
        <p class="price">
          <span class="attribute-name-bottom">{{ $t("receipt.price") }}:</span
          >{{ b.final_price }}{{ b.currency }}
        </p>
        <p>
          <span class="attribute-name-bottom">
            {{ $t("receipt.receipt_text") }}:
          </span>
          <a
            target="_blank"
            style="text-decoration: none"
            @click="toView(b, $event)"
            :title="$t('billing.view_receipt')"
          >
            <span class="p-a" v-if="b.buy_type != 4">
              {{ $t("billing.view_receipt") }}
            </span>
          </a>
          <span v-if="b.is_refund == 1" class="red-text">
            {{ $t("billing.refunded") }}
          </span>
        </p>
      </div>
    </div>
    <el-pagination
      v-if="displayedData.length != 0"
      layout="prev, pager, next, jumper"
      :total="totalCount"
      :page-size="10"
      :current-page="currentPage"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getBillingList } from "@/api/billing.js";
import { mapGetters } from "vuex";

export default {
  name: "billingComponent",
  data() {
    return {
      currentYear: this.$t("billing.all_time"),
      currentPage: 1,
      allYears: [],
      displayedData: [],
      totalCount: 0,
      isSelect: false,
    };
  },
  computed: { ...mapGetters(["isPro"]) },
  created() {
    this.getList(1, 10);
  },
  methods: {
    handlePageChange(page) {
      this.getList(page, 10);
      this.currentPage = page;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    getList(n, m) {
      this.$parent.isRequest = true;
      let y =
        this.currentYear == this.$t("billing.all_time") ? "" : this.currentYear;
      getBillingList(n, m, y).then(res => {
        this.displayedData = res.data.list;
        this.allYears = [this.$t("billing.all_time"), ...res.data.years];
        this.totalCount = res.data.total;
        this.$parent.isRequest = false;
      });
    },
    chooseYear(y) {
      this.currentPage = 1;
      this.currentYear = y;
      this.isSelect = false;
      this.getList(1, 10);
    },
    toView(b, e) {
      sessionStorage.setItem("order_id", b.order_no);
      sessionStorage.setItem("int_id", b.transaction_id);
      this.$navigateTo(e, "/receipt");
    },
  },
};
</script>
