<template>
  <div class="order-complete" ref="container">
    <div class="order-completed-tip-box">
      <div class="o-c-pic">
        <img
          loading="lazy"
          src="@/assets/img/shopcart/order-completed.svg"
          alt="order completed"
        />
      </div>
      <p class="completed-title">{{ $t("order_complete.title") }}</p>
      <p class="completed-sub-tet">
        {{ $t("order_complete.desc") }}
      </p>
    </div>
    <ul class="order-info-listBox">
      <li class="order-single-item">
        <div class="item-title">
          <div class="i-main-t o-common-tit">
            {{ $t("order_complete.order_id") }}:
            <span>{{ info.order_no }}</span>
          </div>
          <div class="i-sub-t o-common-tit">
            {{ $t("order_complete.price") }}:
            <span>{{ info.final_price }} {{ info.currency }}</span>
          </div>
        </div>
        <table class="info-container contnet-container">
          <thead>
            <tr class="o-common-tit">
              <th>{{ $t("order_complete.name") }}</th>
              <th>{{ $t("order_complete.delivery") }}</th>
              <th>{{ $t("order_complete.unit_price") }}</th>
              <th>{{ $t("order_complete.quantity") }}</th>
              <th>{{ $t("order_complete.final") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="i-conetnt"
              v-for="(p, index) in info.products"
              :key="index"
            >
              <td>{{ p.plan_name }}</td>
              <td>{{ $t("order_complete.electronic") }}</td>
              <td>{{ p.original_price }} {{ info.currency }}</td>
              <td>{{ p.quantity }}</td>
              <td>{{ p.final_price }} {{ info.currency }}</td>
            </tr>
          </tbody>
        </table>
        <div
          class="contnet-container mobile-container"
          v-for="(p, index) in info.products"
          :key="index"
        >
          <p class="w-700">{{ $t("order_complete.name") }}</p>
          <p class="color-gray">{{ p.plan_name }}</p>
          <p class="flex-between">
            <span class="w-700">{{ $t("order_complete.delivery") }}</span
            ><span>{{ $t("order_complete.electronic") }}</span>
          </p>
          <p class="flex-between">
            <span class="w-700">{{ $t("order_complete.unit_price") }}</span
            ><span>{{ p.original_price }} {{ info.currency }}</span>
          </p>
          <p class="flex-between">
            <span class="w-700">{{ $t("order_complete.quantity") }}</span
            ><span>{{ p.quantity }}</span>
          </p>
          <p class="flex-between">
            <span class="w-700">{{ $t("order_complete.final") }}</span
            ><span>{{ p.final_price }} {{ info.currency }}</span>
          </p>
        </div>
      </li>

      <li class="order-single-item detail-item">
        <div class="item-title">
          <div class="i-main-t o-common-tit">
            {{ $t("order_complete.detail") }}
          </div>
          <div class="i-sub-t"></div>
        </div>
        <div class="contnet-container">
          <p class="o-common-tit">
            {{ $t("order_complete.license_email") }}:
            <span>{{ userEmail }}</span>
          </p>
          <p>{{ $t("order_complete.license_tip") }}</p>
          <p class="o-common-tit">
            {{ $t("order_complete.contact_text") }}
            <a :href="`mailto:support@${vendorInfo.vendor}.com`" title="support"
              >support@{{ vendorInfo.vendor }}.com</a
            >
          </p>
        </div>
      </li>
    </ul>
    <p class="link" @click="$navigateTo($event, '/personal')">
      {{ $t("receipt.back") }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getOrderDetail } from "@/api/common.js";

export default {
  name: "orderComplete",
  components: {},
  data() {
    return {
      info: {},
    };
  },
  computed: {
    ...mapGetters(["isPro", "vendorInfo"]),
    userEmail() {
      return this.$store.getters.accountInfo
        ? this.$store.getters.accountInfo.emial
        : "";
    },
  },
  methods: {},
  mounted() {
    const orderId = sessionStorage.getItem("order_id")
      ? sessionStorage.getItem("order_id")
      : this.$route.query.order_no;
    getOrderDetail(orderId, sessionStorage.getItem("int_id"))
      .then(res => {
        this.info = res.data;
      })
      .catch(() => {})
      .finally(() => {
        this.$router.replace("/order-complete");
      });
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/orderComplete";
</style>
