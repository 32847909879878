<template>
  <div class="manage-subscription" ref="container">
    <div>
      <div class="main-content">
        <p class="title">
          {{
            source == "change-payment"
              ? $t("manageSubscription.is_set")
              : $t("manageSubscription.is_add")
          }}
        </p>
        <p class="tip" v-if="source == 'change-payment'">
          {{ $t("manageSubscription.pay_tip") }}
        </p>

        <payment ref="payment"></payment>
        <el-button
          class="p-btn usable"
          @click="comfirm"
          v-loading="isLoading"
          >{{ isLoading ? "" : $t("manageSubscription.confirm") }}</el-button
        >
      </div>
      <p class="close-btn" @click="innerChangePage($event)"></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import payment from "@/components/paymentControl.vue";
import { changeSubscribe, operatSub } from "@/api/subscription.js";
import { changePayment } from "@/api/subscription.js";

export default {
  components: { payment },
  name: "addNewCard",
  data() {
    return {
      isLoading: false,
      Airwallex: null,
      cardNumber: null,
    };
  },
  computed: {
    source() {
      return this.$route.params.source;
    },
    ...mapGetters(["isPro", "systemHasLogin"]),
  },
  methods: {
    innerChangePage(e) {
      let pageName;
      switch (this.source) {
        case "manage":
          pageName = `/manage-subscription/${
            JSON.parse(sessionStorage.getItem("currentManageSub")).id
          }`;
          break;
        case "resume":
        case "change-payment":
          pageName = "/personal";
          break;
      }
      this.$navigateTo(e, pageName);
    },
    comfirm() {
      const result = Object.values(this.$refs.payment.elementsCompleted).some(
        value => value === false
      );
      if (result) {
        alert(this.$t("add_new_card.please_fill"));
        return;
      }
      this.isLoading = true;
      switch (this.source) {
        case "manage":
          this.manageSubComfirm();
          break;
        case "resume":
          this.resumeSubComfirm();
          break;
        case "change-payment":
          this.changePaymentComfirm();
      }
    },
    manageSubComfirm() {
      const parameter = JSON.parse(sessionStorage.getItem("parameter"));
      changeSubscribe(parameter)
        .then(response => {
          parameter.sst_id == 9
            ? this.$refs.payment.confirmPayment(response)
            : this.$refs.payment.createConsent(response);
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    resumeSubComfirm() {
      operatSub(sessionStorage.getItem("requsetId"), "recover", {
        currency: sessionStorage.getItem("requestCurrency"),
      })
        .then(response => {
          this.$refs.payment.createConsent(response);
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    successPayment() {
      this.$navigateTo({ text: "新卡支付成功" }, "/personal");
    },
    changePaymentComfirm() {
      changePayment(
        sessionStorage.getItem("requsetId"),
        sessionStorage.getItem("requestCurrency"),
        ""
      )
        .then(response => {
          this.isLoading = false;
          this.$refs.payment.createConsent(response);
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    if (!this.systemHasLogin) {
      this.$navigateTo({ text: "账号未登录" }, "/login");
      return;
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss">
@import "@/styles/manageSubscription";

.p-btn {
  width: 100%;
}

.tip {
  font-size: 14px;
  color: #aaa;
  margin-bottom: 20px;
}
</style>
