<template>
  <div class="video-downloader">
    <div class="search-container">
      <div class="inp-wrap">
        <label>
          <input
            type="text"
            class="website-input"
            placeholder="Paste the Video URL to Download"
            v-model="url"
          />
        </label>
      </div>
      <div class="download-btn">
        <p
          v-show="!processing"
          :class="['p-btn', processResult ? 'black' : 'usable']"
          @click="handleDownloadVideo"
        >
          <i class="icon">
            <img
              loading="lazy"
              src="@/assets/img/online-video-downloader/download-fff.svg"
              alt="download"
            />
          </i>
          Download
        </p>
        <div
          class="animation-container download-btn p-btn black"
          v-show="processing"
        >
          <div class="stick"></div>
          <div class="stick"></div>
          <div class="stick"></div>
          <div class="stick"></div>
          <div class="stick"></div>
          <div class="stick"></div>
        </div>
      </div>
    </div>
    <h3 class="downloader-title">
      We also provide the PC version of the Video Downloader
    </h3>
    <div class="downloader-container">
      <div class="left">
        <div class="l-tb">
          <p class="version-title">Functional Characteristics</p>
          <p>
            Maximum <br />
            Resolution
          </p>
          <p>
            Downloadable <br />
            Websites
          </p>
          <p>
            Conversion <br />
            Stability
          </p>
          <p>Parse Download List</p>
          <p>Merge Multilingual Subtitles</p>
        </div>
        <div class="m-tb">
          <p class="version-title">Online Version</p>
          <p>720p</p>
          <p>Limited</p>
          <p>Low</p>
          <p class="wrong"></p>
          <p class="wrong"></p>
        </div>
        <div class="r-tb">
          <p class="version-title">PC Version</p>
          <p>4K</p>
          <p>Wide-Ranging</p>
          <p>High</p>
          <p class="correct"></p>
          <p class="correct"></p>
        </div>
      </div>
      <div class="right">
        <div class="arrow-container"><p class="arrow"></p></div>
        <div class="product-introduce">
          <picture>
            <img
              class="product-logo"
              loading="lazy"
              src="@/assets/img/dumpMedia/video-downloader.png"
              alt="Video Downloader"
            />
          </picture>
          <h3>Video Downloader</h3>
          <div class="introduce-main">
            <div :class="['btn-group', isScale ? 'scale-animate' : '']">
              <a
                class="p-btn usable"
                href="https://www.dumpmedia.com/download/video-downloader.pkg"
                title="Free Download"
              >
                <img src="@/assets/img/icon/mac-white.svg" alt="Mac" />
                <span>Free Download</span>
              </a>
              <a
                class="p-btn usable"
                href="https://www.dumpmedia.com/download/video-downloader.exe"
                title="Free Download"
              >
                <img src="@/assets/img/icon/win-white.svg" alt="Win" />
                <span> Free Download</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-wrap">
      <div class="dl-section" ref="dlSection" v-show="processResult">
        <div class="l-video-info">
          <img
            loading="lazy"
            class="video-adt"
            :src="
              videoInfo['image'] ||
              '@/assets/img/online-video-downloader/default.png'
            "
            :alt="videoInfo['title'] || 'Video Cover Not Found'"
          />
          <div class="v-info">
            <div class="in-title mb-10">{{ videoInfo["title"] }}</div>
            <div class="in-Duration mb-10">
              Duration: <span>{{ timeChange(videoInfo["duration"]) }}</span>
            </div>
            <div class="in-Views mb-10">
              Views: <span>{{ formatViewCount(videoInfo["view_count"]) }}</span>
            </div>
            <ul class="in-Share">
              <li class="share-label">Share:</li>
              <li
                class="share"
                @click="
                  share('Twitter', 'https://twitter.com/intent/tweet?url=')
                "
              >
                <img
                  src="@/assets/img/online-video-downloader/twitter.svg"
                  alt="twitter"
                  loading="lazy"
                />
              </li>
              <li
                class="share"
                @click="
                  share('Facebook', 'https://www.facebook.com/sharer.php?u=')
                "
              >
                <img
                  src="@/assets/img/online-video-downloader/facebook-thin.svg"
                  alt="facebook"
                  loading="lazy"
                />
              </li>
              <li class="share" @click="share('Copylink')">
                <img
                  src="@/assets/img/online-video-downloader/share-link-000.svg"
                  alt="share"
                  loading="lazy"
                />
              </li>
            </ul>
          </div>
        </div>
        <div class="r-video-info">
          <ul class="header-s-bar">
            <li
              :class="['select-item', type == 'videos' ? 'active-s-item' : '']"
              @click="type = 'videos'"
            >
              Video
            </li>
            <li
              :class="['select-item', type == 'audios' ? 'active-s-item' : '']"
              @click="type = 'audios'"
            >
              Audio
            </li>
            <li
              :class="[
                'select-item',
                type == 'subtitle' ? 'active-s-item' : '',
              ]"
              @click="type = 'subtitle'"
            >
              Subtitles
            </li>
          </ul>
          <ul class="dl-tb">
            <li class="dl-table-tit">
              <ul class="dl-title-wrap common-dl-sty">
                <li class="dl-list-item">Format</li>
                <li class="dl-list-item">Quality</li>
                <li class="dl-list-item">Size</li>
              </ul>
            </li>
            <li
              class="dl-table-ct"
              v-for="(item, key) in downloadData[type]"
              :key="key"
            >
              <ul class="dl-ct-wrap common-dl-sty" v-if="type == 'subtitle'">
                <li class="dl-ct-item">{{ item.format }}</li>
                <li class="dl-ct-item">{{ item.quality }}</li>
                <li class="dl-ct-item">
                  {{ item.size ? bytesToSize(item.size) : "N/A" }}
                </li>
                <li class="dl-ct-item">
                  <p
                    class="p-btn usable"
                    :class="
                      downloading == `${type}-${key}-0` ? 'isLoading' : ''
                    "
                    @click="downloadMedia(key, item)"
                  >
                    Download
                  </p>
                </li>
              </ul>
              <ul
                class="dl-ct-wrap common-dl-sty"
                v-for="(item, i) in downloadData[type][key]"
                :key="i"
                v-else
              >
                <li class="dl-ct-item">{{ item.format }}</li>
                <li class="dl-ct-item">
                  {{ item.quality }}
                  <img
                    v-show="'has_audio' in item && !item.has_audio"
                    src="@/assets/img/dumpMedia/volume_off.svg"
                    alt="without audio"
                  />
                </li>
                <li class="dl-ct-item">
                  {{ item.size ? bytesToSize(item.size) : "N/A" }}
                </li>
                <li class="dl-ct-item">
                  <p
                    class="p-btn usable"
                    :class="
                      downloading == `${type}-${key}-${i}` ? 'isLoading' : ''
                    "
                    @click="downloadMedia(key, item, i)"
                  >
                    Download
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="preview-container" v-show="downloadResult">
          <p>Tip: Click more options, select download</p>
          <div class="video-container">
            <video
              v-show="downloadType == 'videos'"
              ref="previewVideo"
              src=""
              controls
              autoplay
            ></video>
            <audio
              v-show="downloadType == 'audios'"
              ref="previewAudio"
              src=""
              controls
              autoplay
            ></audio>
          </div>
        </div>
      </div>
      <div class="pro-dl-other-way">
        <p>
          Download Video and Mp3 from YouTube, Facebook, Instagram, Vimeo,
          SoundCloud, etc.
        </p>
        <ul class="way-wrap">
          <li class="way-icon">
            <picture>
              <img
                loading="lazy"
                src="@/assets/img/online-video-downloader/youtube.svg"
                alt="youtube"
              />
            </picture>
          </li>
          <li class="way-icon">
            <picture>
              <img
                loading="lazy"
                src="@/assets/img/online-video-downloader/facebook.svg"
                alt="facebook"
              />
            </picture>
          </li>
          <li class="way-icon">
            <picture>
              <img
                loading="lazy"
                src="@/assets/img/online-video-downloader/instagram.svg"
                alt="instagram"
              />
            </picture>
          </li>
          <li class="way-icon">
            <picture>
              <img
                loading="lazy"
                src="@/assets/img/online-video-downloader/vimeo.svg"
                alt="vimeo"
              />
            </picture>
          </li>
          <li class="way-icon">
            <picture>
              <img
                loading="lazy"
                src="@/assets/img/online-video-downloader/soundcloud.svg"
                alt="soundcloud"
              />
            </picture>
          </li>
        </ul>
      </div>
      <div class="pro-guide">
        <h2 class="guide-title">Step by Step Guide:</h2>
        <p class="guide-text-desc">How to Download YouTube Video and MP3</p>

        <div class="pic-wrap">
          <div class="step">
            <picture>
              <img
                loading="lazy"
                src="@/assets/img/online-video-downloader/step-one.png"
                alt="step One"
              />
            </picture>
            <div class="text-wrap">
              <h3 class="text-title">Step 1</h3>
              <p class="text-content">
                Copy the Video URL that you want to download and paste it to the
                "Search" box. Then click "Download"
              </p>
            </div>
          </div>

          <div class="step">
            <picture>
              <img
                loading="lazy"
                src="@/assets/img/online-video-downloader/step-two.png"
                alt="step Two"
              />
            </picture>
            <div class="text-wrap">
              <h3 class="text-title">Step 2</h3>
              <p class="text-content">
                Choose A Format to Download. You can select Video or Audio.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="star-features">
        <h2 class="star-features-title">Star Features</h2>
        <ul class="intro-desc-wrap">
          <li class="intro-item">
            <div class="icon-l">
              <picture>
                <img
                  loading="lazy"
                  src="@/assets/img/online-video-downloader/anywhere-run.svg"
                  alt="It Can Be Used Anywhere"
                />
              </picture>
            </div>
            <div class="text-r">
              <h3 class="func-abstract">It Can Be Used Anywhere</h3>
              <p class="func-desc-text">
                DumpMedia Online Downloader can be easily used on any browsers
                on Windows/Mac OS, Android, iPhone/iPad and all kinds of Tablet.
              </p>
            </div>
          </li>
          <li class="intro-item">
            <div class="icon-l">
              <picture>
                <img
                  loading="lazy"
                  src="@/assets/img/online-video-downloader/website-supported.svg"
                  alt="Lots of Websites Supported"
                />
              </picture>
            </div>
            <div class="text-r">
              <h3 class="func-abstract">Lots of Websites Supported</h3>
              <p class="func-desc-text">
                Download SD and HD as well as 4K videos from YouTube and other
                video sites including Facebook, Instagram, SoundCloud and more.
              </p>
            </div>
          </li>
          <li class="intro-item">
            <div class="icon-l">
              <picture>
                <img
                  loading="lazy"
                  src="@/assets/img/online-video-downloader/download-round.svg"
                  alt="Totally Free Online Download"
                />
              </picture>
            </div>
            <div class="text-r">
              <h3 class="func-abstract">Totally Free Online Downloder</h3>
              <p class="func-desc-text">
                Online Downloader is a free online service. You don't need to
                download any software. It supports you to download YouTube Video
                or convert it to MP3.
              </p>
            </div>
          </li>
          <li class="intro-item">
            <div class="icon-l">
              <picture>
                <img
                  loading="lazy"
                  src="@/assets/img/online-video-downloader/diamonds.svg"
                  alt="Download High Quality MP3 File"
                />
              </picture>
            </div>
            <div class="text-r">
              <h3 class="func-abstract">Donwload High Quality MP3 File</h3>
              <p class="func-desc-text">
                Download YouTube MP3 in desired quality like 320kbps, 256kbps,
                128kbps, 96kbps as long as available. Download YouTube Music or
                MV in a click and listen to YouTube offline.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="multi-ouput">
        <h3 class="muti-title">Multiple Output Format and Resolution</h3>
        <ul class="intro-func-section">
          <li class="func-intro">
            <img
              loading="lazy"
              src="@/assets/img/online-video-downloader/video-round.svg"
              alt="Video Format"
            />
            <div class="text-r">
              <div class="text-section">
                <p>Video Format:</p>
                <p>WEBM, 3GP, MP4</p>
              </div>
              <div class="text-section">
                <p>Quality:</p>
                <p>144p, 240p, 360p, 720p, 1080p, 2K, 4K, 8K</p>
              </div>
            </div>
          </li>
          <li class="func-intro">
            <div class="img-l">
              <picture>
                <img
                  loading="lazy"
                  src="@/assets/img/online-video-downloader/audio-round.svg"
                  alt="Audio Format"
                />
              </picture>
            </div>
            <div class="text-r">
              <div class="text-section">
                <p>Audio Format:</p>
                <p>M4A, WEBM, MP3</p>
              </div>
              <div class="text-section">
                <p>Quality:</p>
                <p>64kbps, 96kbps, 128kbps, 256kbps, 320kbps</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import encrypt from "@/utils/encrypt";
import encrypt from "@/utils/encrypt-dev";
import request from "@/utils/request";
import { dlTracking } from "@/utils/parse";

export default {
  name: "videoDownloader",
  props: {},
  data() {
    return {
      url: "",
      type: "videos",
      downloadData: {},
      videoInfo: {},
      processing: false,
      processResult: false,
      downloading: "",
      downloadResult: false,
      downloadType: "",
      isScale: false,
      shareLink: "",
    };
  },
  computed: {
    ...mapGetters(["vendorInfo"]),
  },
  methods: {
    handleDownloadVideo() {
      this.processing = true;
      this.processResult = false;
      const r = new encrypt(request);
      r.downloadVideo(this.url)
        .then(res => {
          r.searchTask(res?.data?.key, "video")
            .then(res => {
              const result =
                res?.data?.data?.status == 200
                  ? ["Success"]
                  : ["Failed", res.data.error];
              dlTracking("AnalyzeResult", [...result, this.url]);
              this.renderResult(res.data.data);
            })
            .catch(err => {
              dlTracking("AnalyzeResult", [
                "Failed",
                this.url,
                err.data?.message || err,
              ]);
              this.processing = false;
              this.errorAlert();
            });
        })
        .catch(err => {
          this.processing = false;
          if (
            err.response?.data?.status == 462 &&
            err.response?.data?.message == "Exceeded usage times."
          ) {
            this.promptDownload();
            dlTracking("AnalyzeResult", [
              "Rejected",
              this.url,
              err.data?.message || err,
            ]);
            return;
          }
          dlTracking("AnalyzeResult", [
            "Failed",
            this.url,
            err.data?.message || err,
          ]);
          this.errorAlert();
        });
    },
    errorAlert() {
      this.$parent.isError = true;
      this.$parent.popupText =
        "You may give an incorrect URL.Change a URL or try again.";
    },
    renderResult(data) {
      this.processResult = true;
      this.processing = false;
      this.downloadData = data.data;
      this.videoInfo = data;
      const first720p =
        Object.values(data.data.videos).flatMap(arr =>
          arr.find(item => item.quality === "720p")
        )[0] || false;
      this.shareLink = first720p
        ? first720p.url
        : Object.values(data.data.videos).flat().pop().url;
      this.$nextTick(() => {
        this.$refs.dlSection.scrollIntoView({ behavior: "smooth" });
      });
    },
    promptDownload() {
      const element = document.querySelector(".downloader-title");
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }
      this.isScale = true;
      let timer = setTimeout(() => {
        this.isScale = false;
        clearTimeout(timer);
      }, 2000);
    },
    // date format
    timeChange(result) {
      var h = Math.floor(result / 3600);
      var m = Math.floor((result / 60) % 60);
      var s = Math.floor(result % 60);
      if (h < 10) h = "0" + h;
      if (m < 10) m = "0" + m;
      if (s < 10) s = "0" + s;
      return h + ":" + m + ":" + s;
    },
    //file size format
    bytesToSize(bytes) {
      if (!bytes) return "N/A";
      var k = 1024;
      const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return (bytes / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
    },
    //view count format
    formatViewCount(num) {
      if (num == null) return "N/A";
      num = parseInt(num);
      if (num <= 0) return "N/A";
      var size = "N/A";
      if (num < 1 * 1000) {
        size = num;
      } else if (num < 1 * 1000 * 1000) {
        size = (num / 1000).toFixed(0) + "K";
      } else if (num < 1000 * 1000 * 1000) {
        size = (num / (1000 * 1000)).toFixed(0) + "M";
      } else if (num < 1000 * 1000 * 1000 * 1000) {
        size = (num / (1000 * 1000 * 1000)).toFixed(0) + "B";
      }
      return size;
    },
    downloadMedia(key, item, i = 0) {
      if (this.type == "subtitle") {
        dlTracking("DownloadItem", [
          item.url,
          this.url,
          "cc",
          item.format,
          key,
        ]);
        window.open(
          `${window.reqUrl}/multimedia/subtitle_download?url=${btoa(item.url)}`,
          "_blank"
        );
        return;
      }
      dlTracking("DownloadItem", [
        item.url,
        this.url,
        this.type,
        item.format,
        item.size,
        item.quality,
      ]);
      this.downloadType = this.type;
      this.downloading = `${this.type}-${key}-${i}`;
      let e = this.$refs.previewAudio;
      if (this.type == "videos") {
        this.shareLink = item.url;
        e = this.$refs.previewVideo;
        if (parseFloat(item.quality) > 720) {
          this.downloading = "";
          this.promptDownload();
          return;
        }
      }
      e.src = item.url;
      e.addEventListener("loadeddata", () => {
        this.downloadResult = true;
        dlTracking("DownloadResult", [
          "预览成功，可选择下载",
          this.url,
          item.url,
          this.type,
          item.format,
          item.size,
          item.quality,
        ]);
        this.downloading = "";
        this.$nextTick(() => {
          e.scrollIntoView({ behavior: "smooth" });
        });
      });
      e.addEventListener("error", function (err) {
        this.downloading = "";
        let errorCode = err.target.error.code;
        let errorMessage = "";
        switch (errorCode) {
          case 1:
            errorMessage =
              "MEDIA_ERR_ABORTED - fetching process aborted by user";
            break;
          case 2:
            errorMessage =
              "MEDIA_ERR_NETWORK - error occurred when downloading the media";
            break;
          case 3:
            errorMessage =
              "MEDIA_ERR_DECODE - error occurred when decoding the media";
            break;
          case 4:
            errorMessage =
              "MEDIA_ERR_SRC_NOT_SUPPORTED - The media could not be loaded, either because the server or network failed or because the format is not supported.";
            break;
          default:
            errorMessage = "An unknown error occurred.";
        }
        dlTracking("DownloadResult", [
          "视频加载失败",
          errorMessage,
          this.url,
          item.url,
          this.type,
          item.format,
          item.size,
          item.quality,
        ]);
        this.downloading = "";
        alert("Video loading failed, please try again");
      });
    },
    share(type, base) {
      if (type == "Copylink") {
        navigator.clipboard
          .writeText(this.shareLink)
          .then(() => {
            alert("Copied successfully");
          })
          .catch(() => {
            alert("Copy failed");
          });
      } else {
        window.open(base + this.shareLink, "_blank");
      }
      dlTracking("ClickShare", [type, this.shareLink]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/videoDownloader";
</style>
