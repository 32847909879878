<template>
  <div class="mask">
    <div class="content">
      <img
        class="close-btn"
        src="@/assets/img/icon/close.png"
        alt="close"
        @click="$emit('close')"
      />
      <img
        :src="require(`/src/assets/img/personal/celebrate-${size}.png`)"
        alt="celebrate"
      />
      <div class="text">
        <p>{{ $t("celebrate") }}</p>
        <p>{{ num }}（S）</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      size: "lg",
      num: 3,
    };
  },
  methods: {
    getSize() {
      const w = window.innerWidth;
      this.size = w <= 576 ? "sm" : w <= 1200 ? "md" : "lg";
    },
  },
  mounted() {
    this.getSize();

    const timer = setInterval(() => {
      this.num -= 1;
      if (this.num === 0) {
        clearInterval(timer);
        sessionStorage.setItem("celebrate", false);
        this.$emit("close");
      }
    }, 1000);
  },
};
</script>
<style lang="scss" scoped>
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgb(0 0 0 / 15%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  border-radius: 8px;
  width: 232px;
  position: relative;
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .text {
    padding: 6px 10px;
    width: fit-content;
    margin: 0 auto;
    text-align: center;

    p {
      @include font("base", 12px, 16.8px, 400);
    }

    :last-of-type {
      color: #5e5e5e;
      margin-top: 8px;
    }
  }

  @include breakpoint-min(sm) {
    width: 359px;

    .text {
      padding: 16px 0;
    }
  }

  @include breakpoint-min(xl) {
    width: 546px;

    .text {
      padding: 36px 0;
    }
  }
}
</style>
